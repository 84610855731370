import { useState, useEffect, useRef } from "react"
import "./multiSelect.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/*
Props:
- label (string): The label or title for the MultiSelect input.
- options (array): An array of options to be displayed in the dropdown.
- value (array/string): An array of selected values. Controlled component.
- onChange (function): A callback function called when the selected values change.
- disabled (boolean): Disables user interaction with the MultiSelect if set to true.
- placeholder (string): Text to display when no options are selected.
- required (boolean): Indicates whether the MultiSelect is required.
- readOnly (boolean): Makes the MultiSelect read-only if set to true.
- error (string): An error message to display when validation fails.
- validation (boolean): Indicates whether the MultiSelect should be validated, if true, the MultiSelect will display a validation icon and message.
- className (string): Additional CSS classes to customize the MultiSelect's appearance.
    -> "default": "multiSelect__component--default"
    -> "disabled": "multiSelect__component--disabled"
    -> "readonly": "multiSelect__component--readonly"

    // for arrow styling
    https://stackoverflow.com/questions/14218307/select-arrow-style-change

*/

const MultiSelect = ({
  label,
  options,
  value,
  onChange,
  disabled,
  placeholder,
  required,
  readOnly,
  className,
  error,
  validation,
  ...props
}) => {
  const [errorMessages, setErrorMessages] = useState("")
  const [isTouched, setIsTouched] = useState(false)
  const [optionsList, setOptionsList] = useState(options)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)

  useState(() => {
    setOptionsList(options)
  }, [options])

  useEffect(() => {
    setSelectedOptions(value)
  }, [value])

  useEffect(() => {
    if (selectedOptions.length > 0) {
      setIsTouched(true)
    } else {
      setIsTouched(false)
    }
  }, [selectedOptions])

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
        // !e.target.classList.contains("multiSelect__filed") &&
        // !e.target.classList.contains("multiSelect__selectedItem") &&
        // !e.target.classList.contains("icon__close")
      ) {
        setIsDropdownOpen(false)
      }
    }
    if (isDropdownOpen) {
      document.addEventListener("click", handleDocumentClick)
    } else {
      document.removeEventListener("click", handleDocumentClick)
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick)
    }
  }, [isDropdownOpen])

  const SelectedItems = ({ option, onClick }) => {
    return (
      <div className='multiSelect__selectedItem'>
        {option}
        {!readOnly && !disabled && (
          <FontAwesomeIcon
            icon='fa-light fa-xmark'
            className='icon__close'
            onClick={() => onClick(option)}
          />
        )}
      </div>
    )
  }

  const DropdownItem = ({ option, onClick }) => {
    const isSelected = selectedOptions.includes(option)
    return (
      <div className='multiSelect__option'>
        <div>
          {option === "Unselect All" ? (
            <FontAwesomeIcon
              icon='fa-light fa-circle-xmark'
              className='icon__close'
              style={{ color: "#CF0A2E" }}
            />
          ) : isSelected ? (
            <FontAwesomeIcon
              icon='fa-light fa-circle-check'
              className='icon__check'
              style={{ color: "#224BFF" }}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className={`multiSelect__optionText multiSelect__optionText--${
            option === "Unselect All"
              ? "unselectAll"
              : isSelected
              ? "selected"
              : "unselected"
          }
        `}
          onClick={() => onClick(option)}
        >
          {option}
        </div>
      </div>
    )
  }

  const handleOpenDropdown = (e) => {
    if (disabled || readOnly) return

    if (
      !e.target.classList.contains("multiSelect__selectedItem") &&
      !e.target.classList.contains("icon__close")
    ) {
      setIsDropdownOpen(!isDropdownOpen)
    }
  }

  const handleOnSelect = (option) => {
    let newSelectedOptions = [...selectedOptions]
    if (selectedOptions.includes(option)) {
      newSelectedOptions = selectedOptions.filter((item) => item !== option)
    } else {
      newSelectedOptions = [...selectedOptions, option]
    }
    setSelectedOptions(newSelectedOptions)
    onChange(newSelectedOptions)
    setIsTouched(newSelectedOptions.length > 0)

    setErrorMessages(
      newSelectedOptions.length > 0
        ? ""
        : error
        ? error
        : `Please select ${label}.`
    )
  }

  const handleRemove = (option) => {
    let newSelectedOptions = [...selectedOptions]
    newSelectedOptions = selectedOptions.filter((item) => item !== option)
    setSelectedOptions(newSelectedOptions)
    onChange(newSelectedOptions)
  }

  return (
    <div className='multiSelect' ref={dropdownRef}>
      {label && (
        <div
          className={`multiSelect__label ${
            disabled
              ? "multiSelect__label--disabled"
              : "multiSelect__lable--default"
          }`}
        >
          {label}
          {label && required && (
            <span
              className={
                disabled ? "input__label--disabled" : "input__label--required"
              }
            >
              *
            </span>
          )}
        </div>
      )}
      <div className='multiSelect__section'>
        <button
          className={`multiSelect__container multiSelect__container--${
            className ? className : "default"
          }`}
          onClick={handleOpenDropdown}
          data-touched={isTouched && !disabled && !readOnly && validation}
        >
          <div className='multiSelect__filed'>
            {selectedOptions.length > 0 ? (
              selectedOptions.map((option) => {
                return (
                  <SelectedItems
                    key={option}
                    option={option}
                    onClick={handleRemove}
                  />
                )
              })
            ) : (
              <div
                className={`multiSelect__placeholder--${
                  disabled ? "disabled" : "default"
                }`}
              >
                {placeholder}
              </div>
            )}
          </div>
          <div
            className={`multiSelect__dropdownBtn multiSelect__dropdownBtn--${
              disabled ? "disabled" : "default"
            }`}
          >
            <FontAwesomeIcon icon='fa-light fa-chevron-down' />
          </div>
        </button>
        {validation &&
          (errorMessages ? (
            <FontAwesomeIcon
              icon='fa-light fa-circle-exclamation'
              style={{ color: "#CF0A2E" }}
              className='select__icon'
            />
          ) : isTouched && !disabled ? (
            <FontAwesomeIcon
              icon='fa-light fa-circle-check'
              style={{ color: "#008611" }}
              className='select__icon'
            />
          ) : null)}
      </div>
      {validation &&
        (errorMessages !== "" ? (
          <div className='input__validation--invalid'>{errorMessages}</div>
        ) : isTouched ? (
          <div className='input__validation--valid'> {label} looks good!</div>
        ) : null)}
      {isDropdownOpen && (
        <div className='multiSelect__dropdownList'>
          {selectedOptions.length > 0 && (
            <DropdownItem
              option={"Unselect All"}
              onClick={() => setSelectedOptions([])}
            />
          )}
          {optionsList &&
            optionsList.map((option) => {
              return (
                <DropdownItem
                  key={option}
                  option={option}
                  onClick={handleOnSelect}
                />
              )
            })}
        </div>
      )}
    </div>
  )
}

export default MultiSelect
