import "../../../styles/analysisTools/analysis-bundle.css"
import BundleSummary from "./BundleSummary"
import Filter from "./Filter"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import TotalSavings from "../components/TotalSavings"
import FinancialAnalysisBundle from "../components/FinancialAnalysisBundle"
import { calculateECMBundle } from "../../../utils/HttpClient"
import Incentives from "./Incentives"
import { Sofiac, CMHC } from "./IncentiveOrganiztionDetail.js"

/**
 *  props.data = data.all_data.analysis
 *  {
 *     0: ecm.ecm_identifier
 *     1: participant.site_name
 *     2: participant.province
 *     3: ecm.description
 *     4: ecm.proposed_cost_to_install
 *     5: ecm.base_case_cost_to_install
 *     6: ecm.irr
 *     7: ecm.simple_payback
 *     8: ecm.npv
 *     9: ecm.roi
 *     10: ecm.gas_gj_savings_per_year
 *     11: ecm.electric_kwh_savings_per_year
 *     12: ecm.electric_kw_savings
 *     13: ecm.water_savings_m3
 *     14: ecm.incentive
 *     15: ecm.first_year_utility_savings
 *     16: ecm.group_number
 *     17: ecm.hierarchy
 *     18: ecm.year_of_implementation
 *  }
 *
 *  0 - 8 are for the Analysis page
 *  9 - 16 are for the Bundle page
 *  17 is for the Decarb page
 *  18 is for "Select All", hierarchy for ECM groups
 *
 */

function Bundle(props) {
  // for the bundle summary table
  const [bundleECMs, setBundleECMs] = useState([])

  // for the bundle summary table
  const [calculateBundle, setCalculateBundle] = useState(false)
  const [savingsData, setSavingsData] = useState({})
  const [financialData, setFinancialData] = useState({})
  const [searchSiteProjectNumber, setSearchSiteProjectNumber] = useState(0)
  const [sofiacQualification, setSofiacQualification] = useState({})
  const [cmhcLevel, setCmhcLevel] = useState("")
  const [selectedBuildingECMs, setSelectedBuildingECMs] = useState([])
  const [isCreateBundle, setIsCreateBundle] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    let search_project_number = JSON.parse(
      localStorage.getItem("search_project_number")
    )
    if (search_project_number) {
      setSearchSiteProjectNumber(search_project_number)
      localStorage.removeItem("search_project_number")
    }
  })

  useEffect(() => {
    if (selectedBuildingECMs.length > 0) {
      createBundle(selectedBuildingECMs)
    } else {
      clearBundle()
    }
  }, [selectedBuildingECMs])

  function createBundle(ecm_bundle) {
    if (ecm_bundle.length > 0) {
      setBundleECMs(ecm_bundle)
      setCalculateBundle(true)
      let ecm_identifiers = []
      for (let ecm of ecm_bundle) {
        ecm_identifiers.push(ecm.ecm_identifier)
      }
      getECMData(ecm_identifiers.toString())
    }
  }

  function clearBundle() {
    setBundleECMs([])
    setCalculateBundle(false)
    setSavingsData({})
    setFinancialData({})
    setSofiacQualification({})
    setCmhcLevel("")
  }

  function getECMData(ecm_identifiers) {
    calculateECMBundle(ecm_identifiers)
      .then((res) => {
        setSavingsData(res.data.TotalEnergySavings)
        setFinancialData(res.data.FinancialAnalysis)
        setSofiacQualification(res.data.IsSofiacQualified)
        setCmhcLevel(res.data.CmhcLevel.CmhcLevel)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  function getSofiacIncentive() {
    let result = []

    if (Object.keys(sofiacQualification).length !== 0) {
      result[0] = sofiacQualification.IsSofiacCriteria1
        ? "Eligible"
        : "Not Eligible"
      result[1] = sofiacQualification.IsSofiacCriteria2
        ? "Eligible"
        : "Not Eligible"
      result[2] = sofiacQualification.IsSofiacCriteria3
        ? "Eligible"
        : "Not Eligible"
    } else {
      result[0] = "No available data"
      result[1] = "No available data"
      result[2] = "No available data"
    }
    return result
  }

  return (
    <div className='mb-16'>
      <div className='title w-full flex justify-between items-center'>
        <h1 className='section_heading_1 '>Retrofit Bundle</h1>
        <button className='button-action-white' onClick={() => navigate(-1)}>
          {searchSiteProjectNumber > 0
            ? "back to report"
            : "back to analysis tools"}
        </button>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>
      <Filter
        data={props.data}
        search_site_project_number={searchSiteProjectNumber}
        create_group={createBundle}
        delete_group={clearBundle}
        cancel_button_text='Delete Bundle'
        submit_button_text='Create Bundle'
        set_sofiac_qualification={setSofiacQualification}
        set_cmhc_level={setCmhcLevel}
        ghg_reduction_value={savingsData["GHGEmissions"]}
        set_selected_building_ecms={setSelectedBuildingECMs}
        set_is_create_bundle={setIsCreateBundle}
      />
      {isCreateBundle ? (
        <>
          <BundleSummary bundle_ecms={bundleECMs} />
          {calculateBundle ? (
            <>
              <TotalSavings data={savingsData} />
              <FinancialAnalysisBundle data={financialData} />
            </>
          ) : (
            <></>
          )}
          <Incentives data={Sofiac} result={getSofiacIncentive()} />
          <Incentives data={CMHC} result={cmhcLevel} />
        </>
      ) : (
        <>
          <BundleSummary bundle_ecms={[]} />
          <Incentives
            data={Sofiac}
            result={[
              "No available data",
              "No available data",
              "No available data",
            ]}
          />
          <Incentives data={CMHC} result={""} />
        </>
      )}
    </div>
  )
}
export default Bundle
