import { useState, useEffect, useRef } from "react"
import FileUploaderModal from "../global/components/fileUploader/FileUploaderModal"
import FileDetailsTable from "../global/components/fileUploader/FileDetailsTable"
import InstructionalText from "../global/components/InstructionalText/InstructionalText"
import Button from "../global/components/button/Button"
import "./importBuildings.css"

const apiUrl = process.env.REACT_APP_API_URL

function ImportBuildings({ user_data, building_schema }) {
  // building schema - identifier for destination
  const [buildingSchema, setBuildingSchema] = useState(building_schema)

  // user data - use to confirm user access
  const [userData, setUserData] = useState(user_data)

  // list of uploaded files - for the session
  const [sessionFilesList, setSessionFilesList] = useState([])

  // list of

  // file uploader modal ref
  const file_uploader_modal = useRef(null)

  // remove file from list handler
  function removeFileFromListHandler(index) {
    let new_list = [...sessionFilesList]
    new_list.splice(index, 1)
    setSessionFilesList(new_list)
  }

  function handleOnDownloadFile(file) {
    let url = ""
    if (file.file_blob instanceof File) {
      const fileType = file.file_blob.type || "application/octet-stream"
      // Create a URL for the blob object
      url = window.URL.createObjectURL(
        new Blob([file.file_blob], { type: fileType })
      )
    } else {
      url = apiUrl + file.file_path
    }

    // Create an anchor element (`<a>`) for the download
    const link = document.createElement("a")

    // Set the download attribute with the filename
    link.setAttribute("download", file.file_name)

    // Hide the element
    link.style.display = "none"

    // Set the href of the link to the blob URL
    link.href = url

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically trigger the click event
    link.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }, 100) // Delay cleanup by 100ms or more if needed
  }

  // update name function
  function handleOnEditFileNames(index, new_name, isFolder) {
    console.log("handleOnEditFileNames", index, new_name, isFolder)

    // let new_property_supporting_documents =
    //   userDraftState.property_supporting_documents
    // if (!isFolder) {
    //   new_property_supporting_documents[index].file_name = new_name
    // } else {
    //   new_property_supporting_documents[index].folder_name = new_name
    // }
    // userDraftDispatch({
    //   type: "property_documents_update_all",
    //   payload: new_property_supporting_documents,
    // })
  }

  // function to call API to start job
  // if successful, show job started message
  // call status API every 2 seconds for job status
  // when successful stop loader and show results
  // if failed show error message
  function startImportJobHandler() {
    console.log("startImportJobHandler")
    // call start job API
    // if successful, show job started message
    // call status API every 2 seconds for job status
    // when successful stop loader and show results
    // if failed show error message
  }

  // function to call status API
  // if successful, stop loader and show results
  // if failed show error message
  function checkJobStatusHandler() {
    console.log("checkJobStatusHandler")
    // call status API every 2 seconds for job status
    // when successful stop loader and show results
    // if failed show error message
  }

  return (
    <div className='mb-16'>
      <div className='title'>
        <h1 className='section_heading_1'>Import Buildings</h1>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>
      <div className='import_container'>
        <InstructionalText
          type='Main Instructions'
          title='Upload formatted excel files to import buildings'
          content={
            <>
              In this section, upload formatted excel files to import building
              data into SISA Platform and generate reports.
            </>
          }
        />
        <div className='form-title-button__container'>
          <Button
            type='button'
            className='button  button__large button__large--secondary'
            buttonText='Select Files'
            onClick={() => file_uploader_modal.current.showModal()}
          />
        </div>
        <FileDetailsTable
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "uploaded_date",
            "status",
            "download",
            "delete",
          ]}
          data_list={sessionFilesList}
          set_data_list={setSessionFilesList}
          fn_remove_row={removeFileFromListHandler}
          fn_download_file={handleOnDownloadFile}
          fn_edit_name={handleOnEditFileNames}
        />

        <FileUploaderModal
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "message",
            "status",
            "delete",
          ]}
          modal_header='Upload Building Assessments'
          content_title='Select spreadsheet template files.'
          content_description='You can store up to 100MB in our secure cloud servers. The maximum file size supported is 5MB.
        Our platform supports the following file types: Formatted Excel spreadsheets (.xlsx).'
          modal_ref={file_uploader_modal}
          uploaded_tableData={sessionFilesList}
          fn_remove_row={removeFileFromListHandler}
          set_uploaded_tableData={setSessionFilesList}
          user_data={userData}
          accepted_file_types={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ]}
          fn_upload_handler={startImportJobHandler}
        />
      </div>
    </div>
  )
}
export default ImportBuildings
