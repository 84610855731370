import { useState } from "react"
import "./textarea.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/*
Props:
- label: (String) Text label for the textarea.
- name: (String) HTML name attribute.
- value: (String) Controlled value for the textarea.
- onChange: (Function) Function called when textarea changes.
- placeholder: (String) Placeholder text.
- icon: (React Element) FontAwesome icon, import from "@fortawesome/pro-light-svg-icons".
- iconColor: (String) Color of the icon (if any).
- iconPosition: (String) Position of the icon inside the textarea ('right', 'left', etc.)
- pattern: (String) A Regex pattern for validation.
- validation (boolean): Indicates whether the MultiSelect should be validated, if true, should include the "pattern" in the prop, the MultiSelect will display a validation icon and message.
- min: (Number/String) Min value for types like "number" or "date".
- max: (Number/String) Max value for types like "number" or "date".
- rows: (Number) Specifies the number of rows in the textarea.
- cols: (Number) Specifies the number of columns for the textarea.
- disabled: (Boolean) Boolean to disable textarea.
- required: (Boolean) Boolean to mark input as required, adds a red asterisk to the label.
- readOnly: (Boolean) Boolean for read-only state.
- error: (String) Custom error message to display if the validation fails.
- className: (String) Additional classes to add to the textarea.
    -> "default": "textarea__field--default"
    -> "disabled": "textarea__field--disabled"
    -> "readonly": "textarea__field--readonly"
*/

const Textarea = ({
  label,
  name,
  value,
  onChange,
  className = "default",
  placeholder,
  icon,
  iconColor,
  iconPosition = "right",
  pattern,
  min,
  max,
  rows,
  cols,
  disabled,
  required,
  readOnly,
  error,
  validation,
}) => {
  const [errorMessages, setErrorMessages] = useState("")
  const [isTouched, setIsTouched] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const handleOnChange = (e) => {
    onChange(e.target)

    setIsTouched(e.target.value !== "")

    if (pattern) {
      const regex = new RegExp(pattern)
      setErrorMessages(
        regex.test(e.target.value)
          ? ""
          : error
          ? error
          : `Please provide a valid ${name}.`
      )
      setIsValid(regex.test(e.target.value))
    }
  }

  const renderIcon = () => {
    if (validation) {
      if (isTouched && errorMessages) {
        return (
          <FontAwesomeIcon
            icon='fa-light fa-circle-exclamation'
            style={{ color: "#CF0A2E" }}
            className='textarea__icon textarea__icon--right'
          />
        )
      } else if (isTouched && !disabled && value) {
        return (
          <FontAwesomeIcon
            icon='fa-light fa-circle-check'
            style={{ color: "#008611" }}
            className='textarea__icon textarea__icon--right'
          />
        )
      }
    } else if (icon) {
      return (
        <FontAwesomeIcon
          icon={icon}
          style={{ color: iconColor }}
          className={`textarea__icon textarea__icon--${iconPosition}`}
        />
      )
    }
    return null
  }

  const renderValidationMessage = () => {
    if (validation) {
      if (isTouched && errorMessages) {
        return (
          <div className='textarea__valication textarea__validation--invalid'>
            {errorMessages}
          </div>
        )
      } else if (isTouched && !disabled && value) {
        return (
          <div className='textarea__validation--valid'>{label} looks good!</div>
        )
      }
    }
    return null
  }

  return (
    <div className='textarea'>
      <label
        htmlFor={name}
        className={`textarea__label ${
          disabled ? "textarea__label--disabled" : "textarea__lable--default"
        }`}
      >
        {label}
        {label && required && (
          <span
            className={
              disabled
                ? "textarea__label--disabled"
                : "textarea__label--required"
            }
          >
            *
          </span>
        )}
      </label>
      <div className='textarea__container'>
        <textarea
          id={name}
          name={name}
          value={value}
          onChange={handleOnChange}
          className={`textarea__field textarea__field--icon--${iconPosition} textarea__field--${className} ${
            isTouched && validation
              ? `textarea__field--${isValid ? "valid" : "invalid"}`
              : ""
          }  `}
          placeholder={placeholder}
          pattern={pattern}
          min={min}
          max={max}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          data-touched={isTouched && !disabled && !readOnly && validation}
          rows={rows}
          cols={cols}
        />
        {renderIcon()}
      </div>
      {renderValidationMessage()}
    </div>
  )
}

export default Textarea
