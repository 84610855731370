import { useEffect, useState } from "react"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText"
import Select from "../../../global/components/select/Select"
import RadioGroup from "../../../global/components/RadioButton/RadioGroup"
import Input from "../../../global/components/input/Input"
import Textarea from "../../../global/components/textarea/Textarea"
import {
  ControlTypeHeatingCoolingList,
  HeatingUnitTypeList,
} from "./EquipmentOptions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/**
 * @param {object} input_data - heating data object
 * @param {function} fn_set_input_data - setter function for heating data object
 * @param {object} options_list_obj - heating options list object
 * @param {boolean} on_validation - validation flag
 * @param {function} fn_set_validation_obj - setter function for validation object
 * @returns {JSX.Element} - heating specifications JSX Element
 */
function HeatingSpecs({
  input_data,
  fn_set_input_data,
  options_list_obj,
  on_validation,
  fn_set_validation_obj,
}) {
  // list of options for select components
  const { meters_list, units_list } = options_list_obj

  // end use data object
  const [endUseData, setEndUseData] = useState({})

  // open close details flag
  const [openDetails, setOpenDetails] = useState(true)

  useEffect(() => {
    if (input_data) {
      setEndUseData(input_data)
    }
  }, [input_data])

  function handleEndUseInputChange(newValue) {
    let end_use_name = endUseData.end_use_name
    let end_use_input_name = newValue.name
    let end_use_input_value = newValue.value

    if (end_use_input_name.includes(end_use_name)) {
      end_use_input_name = end_use_input_name.replace(end_use_name, "").slice(1)
    }
    let new_end_use_data = {
      ...endUseData,
      [end_use_input_name]: end_use_input_value,
    }
    fn_set_input_data({
      type: "update_end_use",
      payload: new_end_use_data,
    })
  }

  function handleOpenDetails() {
    setOpenDetails(!openDetails)
  }

  return (
    <div id='heating-specifications'>
      <hr />
      {openDetails ? (
        <>
          <InstructionalText
            type='Main Instructions'
            title={`${endUseData.end_use_name} - Heating Specifications`}
            content={
              <>
                Use this section to provide specifics about any heating
                equipment. Include multiple heating sections if there are
                distinct end-uses. For instance, if a central boiler serves
                "Space Heating," "Outdoor Air Heating," and "Domestic Hot
                Water," create separate sections for each. If there are multiple
                heating sections and specific capacities are allocated through
                mechanisms like heat exchangers, assign the designated capacity
                to each section accordingly. However, if there's no explicit
                allocation, utilize the total available capacity of the main
                system for all heating sections.
              </>
            }
            containerClassName='main-instructions'
          />
          <div className='content part-1'>
            <Select
              label='Heating Unit Type'
              name='heating_unit_type'
              options={HeatingUnitTypeList}
              onChange={handleEndUseInputChange}
              value={endUseData.heating_unit_type}
              placeholder='Select heating unit type'
              className='default'
              addNewOption={true}
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <RadioGroup
              legend='Is this a Primary or a Secondary Unit?'
              name={`${endUseData.end_use_name}_primary_or_secondary_unit`}
              radioOptions={[
                {
                  value: "Primary",
                  label: "Primary",
                },
                {
                  value: "Secondary",
                  label: "Secondary",
                },
              ]}
              onChange={handleEndUseInputChange}
              value={endUseData.primary_or_secondary_unit}
            />
            <Input
              label='Average Efficiency/COP'
              type='number'
              name='average_efficiency_or_cop'
              onChange={handleEndUseInputChange}
              value={endUseData.average_efficiency_or_cop}
              placeholder='Enter average efficiency or COP'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <Input
              label='Capacity Rating'
              type='number'
              name='capacity_rating'
              onChange={handleEndUseInputChange}
              value={endUseData.capacity_rating}
              placeholder='Enter capacity rating'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <Select
              label='Unit'
              name={`${endUseData.end_use_name}_unit`}
              options={units_list}
              onChange={handleEndUseInputChange}
              value={endUseData.unit}
              placeholder='Select unit'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <Select
              label='Meter'
              name={`${endUseData.end_use_name}_meter`}
              options={meters_list}
              onChange={handleEndUseInputChange}
              value={endUseData.meter}
              placeholder='Select meter'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <Select
              label='Control Type'
              name='control_type'
              options={ControlTypeHeatingCoolingList}
              onChange={handleEndUseInputChange}
              value={endUseData.control_type}
              placeholder='Select control type'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <Input
              label='Average Annual Hours of Operation'
              type='number'
              name='average_annual_hours_of_operation'
              min={0}
              max={8760}
              onChange={handleEndUseInputChange}
              value={endUseData.average_annual_hours_of_operation}
              placeholder='(0 to 8760 hrs/yr)'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>
          <div className='content part-2'>
            <RadioGroup
              legend='Unit has backup?'
              name={`${endUseData.end_use_name}_unit_has_backup`}
              radioOptions={[
                {
                  value: "Yes",
                  label: "Yes",
                },
                {
                  value: "No",
                  label: "No",
                },
              ]}
              onChange={handleEndUseInputChange}
              value={endUseData.unit_has_backup}
            />
            <Select
              label='Backup Heating Unit Type'
              name='backup_heating_unit_type'
              options={HeatingUnitTypeList}
              onChange={handleEndUseInputChange}
              value={endUseData.backup_heating_unit_type}
              placeholder='Select backup heating unit type'
              className='default'
              disabled={endUseData.unit_has_backup === "No"}
              addNewOption={true}
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <Input
              label='Backup Efficiency'
              type='number'
              name='backup_efficiency'
              onChange={handleEndUseInputChange}
              value={endUseData.backup_efficiency}
              placeholder='Enter backup efficiency'
              className='default'
              disabled={endUseData.unit_has_backup === "No"}
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <div id='backup-meter'>
              <Select
                label='Backup Meter'
                name='backup_meter'
                options={meters_list}
                onChange={handleEndUseInputChange}
                value={endUseData.backup_meter}
                placeholder='Select backup meter'
                className='default'
                disabled={endUseData.unit_has_backup === "No"}
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
            </div>
            <div id='backup-control-comment'>
              <Input
                label='Backup Control Comment'
                type='text'
                name='backup_control_comment'
                onChange={handleEndUseInputChange}
                value={endUseData.backup_control_comment}
                placeholder='Enter your comments here...'
                className='default'
                disabled={endUseData.unit_has_backup === "No"}
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
            </div>
          </div>
          <div className='content'>
            <RadioGroup
              legend='Unit has heat recovery?'
              name={`${endUseData.end_use_name}_unit_has_heat_recovery`}
              radioOptions={[
                {
                  value: "Yes",
                  label: "Yes",
                },
                {
                  value: "No",
                  label: "No",
                },
              ]}
              onChange={handleEndUseInputChange}
              value={endUseData.unit_has_heat_recovery}
            />
            <Input
              label='Heat Recovery Efficiency'
              type='number'
              name='heat_recovery_efficiency'
              onChange={handleEndUseInputChange}
              value={endUseData.heat_recovery_efficiency}
              placeholder='Enter heat recovery efficiency'
              className='default'
              disabled={endUseData.unit_has_heat_recovery === "No"}
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
            <div id='comments_on_heat_recovery'>
              <Input
                label='Comments on Heat Recovery'
                type='text'
                name='heat_recovery_comment'
                onChange={handleEndUseInputChange}
                value={endUseData.heat_recovery_comment}
                placeholder='Enter your comments here...'
                className='default'
                disabled={endUseData.unit_has_heat_recovery === "No"}
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
            </div>
          </div>
          <div className='content'>
            <div className='general-comments'>
              <Textarea
                label='General Comments'
                type='text'
                name='general_comments'
                onChange={handleEndUseInputChange}
                value={endUseData.general_comments}
                placeholder='Enter your general comments here, e.g., Comments on control...'
                className='default'
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
            </div>
          </div>
          <div className='content'>
            <div className='flex justify-end'>
              <div
                className='end-use-details-button'
                onClick={handleOpenDetails}
              >
                <FontAwesomeIcon icon='fa-light fa-chevron-up' />
                <p className='end-use-details-button-text'>Hide Details</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='content'>
            <div className='flex justify-between'>
              <h2 className='end-use-title'>
                {`${endUseData.end_use_name} - Heating Specifications`}
              </h2>
              <div
                className='end-use-details-button'
                onClick={handleOpenDetails}
              >
                <FontAwesomeIcon icon='fa-light fa-chevron-down' />
                <p className='end-use-details-button-text'>Show Details</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default HeatingSpecs
