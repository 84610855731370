import React, { useState, useEffect } from "react"
import NavigationTab from "./NavigationTab"
import "./navigationTab.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Dropdown from "../Dropdown/Dropdown"

const NavigationTabs = ({
  data,
  activeStep,
  onTabClick,
  showMobileDropdown,
  viewOtherTabsText,
}) => {
  const [showLeftButton, setShowLeftButton] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [firstTabLabel, setFirstTabLabel] = useState(data[0].label)
  const [id, setId] = useState(data[0].id)
  const [iconPosition, setIconPosition] = useState(data[0].iconPosition)
  const [rightIconState, setRightIconState] = useState(data[0].rightIconState)

  const [showRightButton, setShowRightButton] = useState(false)

  // data structure for each tab
  // size - Large or small
  // iconPoistion - null, left or right
  // if iconPosition is on right, then right icon state is either good or error, else null

  useEffect(() => {
    // Calculate whether to show the scroll buttons based on the number of tabs
    if (data.length > 6) {
      // setShowLeftButton(true);
      setShowRightButton(true)
    }
  }, [data])

  const handleScrollRight = () => {
    const container = document.querySelector(".navigationTabs")
    if (container) {
      container.scrollLeft += 200
      setShowLeftButton(true)
    }
  }

  const handleScrollLeft = () => {
    const container = document.querySelector(".navigationTabs")
    if (container) {
      container.scrollLeft -= 200
      const showButton = container.scrollLeft > 0
      setShowLeftButton(showButton)
    }
  }

  // Function to handle clicks on tabs
  const handleTabClick = (id, firstTabLabel, iconPosition, rightIconState) => {
    if (id === "viewOtherTabs") {
      setShowDropdown(!showDropdown)
    } else {
      if (onTabClick) {
        onTabClick(id)
      }
      setShowDropdown(false)
      setId(id)
      setFirstTabLabel(firstTabLabel)
      setIconPosition(iconPosition)
      setRightIconState(rightIconState)
    }
  }

  return (
    <React.Fragment>
      {/* desktop */}
      <ul className='navigationTabs' role='tablist'>
        <button
          role='button'
          aria-label='Scroll left'
          className='navigationTab__left-button'
          onClick={handleScrollLeft}
          style={{ display: showLeftButton ? "block" : "none" }}
        >
          <FontAwesomeIcon
            icon='fa-light fa-left'
            className='navigationTab__arrow-icon'
          />
        </button>
        {data.map((tab) => {
          return (
            <NavigationTab
              key={tab.id}
              id={tab.id}
              label={tab.label}
              iconPosition={tab.iconPosition}
              rightIconState={tab.rightIconState}
              disabled={tab.disabled}
              isSelected={activeStep === tab.id}
              onClick={() => handleTabClick(tab.id)}
              className={tab.className}
            />
          )
        })}
        <button
          role='button'
          aria-label='Scroll right'
          className='navigationTab__right-button'
          onClick={handleScrollRight}
          style={{ display: showRightButton ? "block" : "none" }}
        >
          <FontAwesomeIcon
            icon='fa-light fa-angle-right'
            className='navigationTab__arrow-icon'
          />
        </button>
      </ul>

      {/* mobile */}

      {showMobileDropdown && (
        <ul className='navigationTabs-mobile' role='tablist'>
          <React.Fragment>
            <NavigationTab
              label={firstTabLabel}
              isSelected={true}
              onClick={() =>
                handleTabClick(id, firstTabLabel, iconPosition, rightIconState)
              }
              iconPosition={iconPosition}
              rightIconState={rightIconState}
              className='navigationTab__large navigationTab__small'
            />
            <div
              // isSelected={false}
              onClick={() => handleTabClick("viewOtherTabs")}
              className='navigationTab-mobile__large'
              arial-labelledby='viewOtherTabs'
            >
              View Other Tabs
              <FontAwesomeIcon
                icon='fa-light fa-ellipsis'
                className='navigationTab__dot-icon'
                id='viewOtherTabs'
              />
            </div>
            {showDropdown && (
              <Dropdown
                data={data}
                activeStep={activeStep}
                handleTabClick={handleTabClick}
              />
            )}
          </React.Fragment>
        </ul>
      )}

      {!showMobileDropdown && (
        <ul className='navigationTabs-mobile' role='tablist'>
          {data.map((tab) => {
            return (
              <NavigationTab
                key={tab.id}
                id={tab.id}
                label={tab.label}
                iconPosition={tab.iconPosition}
                rightIconState={tab.rightIconState}
                disabled={tab.disabled}
                isSelected={activeStep === tab.id}
                onClick={() => handleTabClick(tab.id)}
                className={tab.className}
              />
            )
          })}
        </ul>
      )}
    </React.Fragment>
  )
}

export default NavigationTabs
