import React, { useRef, useEffect, useState } from "react"
import "./fileUploaderModal.css"
import FileDetailsTable from "./FileDetailsTable"
import UserStorageSpace from "./UserStorageSpace"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InstructionalText from "../InstructionalText/InstructionalText"
import {
  isMobile,
  isTablet,
  isBrowser,
  BrowserView,
  MobileView,
} from "react-device-detect"

function UploadedFileModal({
  table_headers,
  modal_header,
  content_title,
  content_description,
  modal_ref,
  user_data,
  uploaded_tableData,
  fn_remove_row,
  fn_download_file,
  fn_set_data_list,
  fn_on_edit_handler,
}) {
  const [totalSize, setTotalSize] = useState(0)
  const [tableData, setTableData] = useState([])

  const used_storage = user_data.current_usage_in_mb
  const total_storage = user_data.storage_quota_in_mb

  useEffect(() => {
    setTableData(uploaded_tableData)
    if (uploaded_tableData.length > 0) {
      let total_size = uploaded_tableData.reduce(
        (total, file) => total + file.file_size,
        0
      )
      setTotalSize(total_size)
    } else {
      setTotalSize(0)
    }
  }, [uploaded_tableData])

  function handleClose() {
    modal_ref.current.close()
  }

  return (
    <dialog
      ref={modal_ref}
      className='file-uploader'
      onClose={handleClose ? handleClose : null}
    >
      <div className='dialog-header__container'>
        <p className='dialog__header'>{modal_header}</p>
        <FontAwesomeIcon
          icon='fa-light fa-square-xmark'
          onClick={handleClose}
          className='dialog__close-icon'
        />
      </div>

      <InstructionalText
        type='Main Instructions'
        title={content_title}
        content={content_description}
      />

      <InstructionalText
        type='Additional Instructions'
        title='File status : '
        content={
          <>
            When a file is <span className='bold_text'>Attached</span>, it is
            not uploaded to our servers yet. Once you save the draft, we then
            upload the files to our secure cloud servers and the status will
            become <span className='bold_text'>Uploaded</span>.
          </>
        }
      />

      <div className='storage-indicator__container'>
        <UserStorageSpace
          used_storage={used_storage}
          total_storage={total_storage}
        />
        <div className='file__stats'>
          <p>Selected: {tableData.length}</p>
          <p>
            Total Size:
            {totalSize > 1000000
              ? (totalSize / 1000000).toFixed(1) + " MB"
              : (totalSize / 1000).toFixed(1) + " KB"}
          </p>
        </div>
      </div>
      <FileDetailsTable
        table_headers={table_headers}
        data_list={tableData}
        set_data_list={fn_set_data_list}
        fn_remove_row={fn_remove_row}
        fn_download_file={fn_download_file}
        fn_edit_name={fn_on_edit_handler}
      />
    </dialog>
  )
}

export default UploadedFileModal
