import React, { useState, useRef } from "react"
import "../DownloadDropdown/DownloadDropdown.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function DownloadDropdown(props) {
  // example usage:
  // <DownloadDropdown>
  // <XLSXReport data={userDraftState} /> - option1
  // <ExportCSV data={defaultDraft} /> - option2
  // </DownloadDropdown>

  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const handleOptionClick = (option) => {
    console.log("Selected Option:", option)
    closeDropdown()
  }

  return (
    <div className='dropdown__box' ref={dropdownRef}>
      <div className='dropdown--toggle' onClick={toggleDropdown}>
        <FontAwesomeIcon
          className='dropdown__icon'
          icon='fa-light fa-download'
        />
        <span className='dropdown__text'>Download</span>
      </div>
      {isOpen && (
        <ul className='dropdown__menu'>
          {React.Children.map(props.children, (child, index) =>
            React.cloneElement(child, {
              onClick: () => handleOptionClick(child.props.children),
              key: index,
            })
          )}
        </ul>
      )}
    </div>
  )
}
