import { useState, useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { capitalizeFirstLetter } from "../../../../utils/DataFormatter"
import TableDataCell from "./TableDataCell"
import Input from "../input/Input"
import Select from "../select/Select"
import MultiSelect from "../multiSelect/MultiSelect"

function TableAddDataRow({
  headers_array,
  entity_name,
  fn_add_new_row,
  input_props_array,
  parent_key_header,
  children_key_headers,
  fn_disable_children,
  fn_new_children_values,
  new_row_index,
  disabled,
}) {
  /**
   * Add new row component
   * - row_data: (Object) Object with the data for the row.
   * - entity_name: (String) Name of the item to be added.
   * - fn_add_new_row: (Function) Function to save the new row to the context.
   * - input_props_array: (Array) Array of objects with data about the inputs.
   *
   * 1. add new row with save icon
   * 2. editable row with wrapper for buttons
   *
   */

  // state for switching between add new row and edit row
  const [inputMode, setInputMode] = useState(false)

  // row data object
  const [rowDataObject, setRowDataObject] = useState({})

  // validation for the row
  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})

  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if (input_props_array && input_props_array.length > 0) {
      let new_row_data_object = {}
      for (let i = 0; i < input_props_array.length; i++) {
        let input_props = input_props_array[i]
        if (input_props.header_key.includes("name")) {
          new_row_data_object = {
            ...new_row_data_object,
            [input_props.header_key]: `${capitalizeFirstLetter(
              entity_name
            )} - ${new_row_index}`,
          }
        } else {
          new_row_data_object = {
            ...new_row_data_object,
            [input_props.header_key]: input_props.default_value,
          }
        }
      }
      setRowDataObject(new_row_data_object)
    }
  }, [input_props_array, inputMode])

  useEffect(() => {
    if (parent_key_header && inputMode && rowDataObject[parent_key_header]) {
      let result = fn_disable_children(rowDataObject[parent_key_header])
      setIsDisabled(result)
    }
  }, [rowDataObject])

  const hasRequiredFields = () => {
    // checking reqruied Inputs if they are filled.
    // If false, there are required fields that are not filled.
    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
    //true - filled (empty)/ false - not filled
  }

  var input_components = {}

  for (let i = 0; i < input_props_array.length; i++) {
    let input_props = input_props_array[i]
    let is_child = false

    if (children_key_headers && children_key_headers.length > 0) {
      is_child = children_key_headers.includes(input_props.header_key)
    }

    input_components = {
      ...input_components,
      [input_props.header_key]: (
        <>
          {input_props.input_type === "text" ? (
            <Input
              type='text'
              name={input_props.header_key}
              onChange={onChangeHandler}
              value={rowDataObject[input_props.header_key]}
              placeholder={input_props.place_holder}
              className='default'
              required={input_props.is_required}
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={is_child ? isDisabled : false}
              validation_icon_only={true}
            />
          ) : input_props.input_type === "number" ? (
            <Input
              type='number'
              name={input_props.header_key}
              onChange={onChangeHandler}
              value={rowDataObject[input_props.header_key]}
              placeholder={input_props.place_holder}
              className='default'
              required={input_props.is_required}
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={is_child ? isDisabled : false}
              validation_icon_only={true}
            />
          ) : input_props.input_type === "select" ? (
            <Select
              name={input_props.header_key}
              options={input_props.options}
              onChange={onChangeHandler}
              value={rowDataObject[input_props.header_key]}
              placeholder={input_props.place_holder}
              className='default'
              required={input_props.is_required}
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={is_child ? isDisabled : false}
              addNewOption={input_props.add_new_option}
              validation_icon_only={true}
            />
          ) : input_props.input_type === "multi-select" ? (
            <MultiSelect
              name={input_props.header_key}
              options={input_props.options}
              onChange={onChangeHandler}
              value={rowDataObject[input_props.header_key]}
              placeholder={input_props.place_holder}
              className='default'
              required={input_props.is_required}
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={is_child ? isDisabled : false}
              validation_icon_only={true}
            />
          ) : null}
        </>
      ),
    }
  }

  // update state to show the edit wrapper component
  function addNewRowHandler() {
    setInputMode(true)
    setOnValidation(true)
  }

  function cancelRowHandler() {
    setOnValidation(false)
    // setRowDataObject({})
    setInputMode(false)
  }

  // on save check for required fields and update context
  function onSaveRowDataHandler(new_row_data) {
    console.log("new_row_data", new_row_data)
    setOnValidation(true)

    // TODO: add is_selected prop
    if (hasRequiredFields()) {
      fn_add_new_row(new_row_data)
      setInputMode(false)
    } else {
      console.log("Missing required fields")
    }
  }

  function onChangeHandler(newValue) {
    if (parent_key_header && newValue.name === parent_key_header) {
      let result = fn_disable_children(newValue.value)
      if (result) {
        if (fn_new_children_values) {
          let new_children_values = fn_new_children_values(newValue.value)
          setRowDataObject((dataObject) => ({
            ...dataObject,
            ...new_children_values,
            [newValue.name]: newValue.value,
          }))
        } else {
          setRowDataObject((dataObject) => ({
            ...dataObject,
            [newValue.name]: newValue.value,
          }))
        }
      } else {
        setRowDataObject((dataObject) => ({
          ...dataObject,
          [newValue.name]: newValue.value,
        }))
      }
      setIsDisabled(result)
    } else {
      setRowDataObject((dataObject) => ({
        ...dataObject,
        [newValue.name]: newValue.value,
      }))
    }
  }

  return (
    <div
      className={`table__body_row ${
        disabled ? "table__body_row--disabled" : ""
      }`}
    >
      {inputMode ? (
        <>
          {headers_array.map((header, index) => {
            return (
              <TableDataCell
                key={`${header.header_name}-${index}`}
                header_data={header}
                row_data={rowDataObject}
                fn_update_row={onSaveRowDataHandler}
                fn_cancel_row={cancelRowHandler}
                fn_delete_row={cancelRowHandler}
                input_component={input_components[header.header_key]}
                input_mode={inputMode}
                fn_input_mode={setInputMode}
              />
            )
          })}
        </>
      ) : (
        <div className='table__body_row_add_new' onClick={addNewRowHandler}>
          <p>Add New {capitalizeFirstLetter(entity_name)}</p>
          <FontAwesomeIcon icon='fa-light fa-circle-plus' />
        </div>
      )}
    </div>
  )
}
export default TableAddDataRow
