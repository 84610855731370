import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./alertBanner.css"

import Button from "../../../../global/components/button/Button"

export default function AlertBanner({
  type,
  info,
  isDialog,
  isExpandable,
  expandedContent,
}) {
  const [isAlertVisible, setIsAlertVisible] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isAnimationActive, setIsAnimationActive] = useState(false)

  let icon
  let iconStyle = "alert-banner__icon"

  switch (type) {
    case "information":
      icon = (
        <FontAwesomeIcon icon='fa-light fa-circle-info' className={iconStyle} />
      )
      break
    case "success":
      icon = (
        <FontAwesomeIcon
          icon='fa-light fa-circle-check'
          className={iconStyle}
        />
      )
      break
    case "warning":
      icon = (
        <FontAwesomeIcon
          icon='fa-light fa-triangle-exclamation'
          className={iconStyle}
        />
      )
      break
    case "error":
      icon = (
        <FontAwesomeIcon
          icon='fa-light fa-hexagon-exclamation'
          className={iconStyle}
        />
      )
      break
    default:
      icon = (
        <FontAwesomeIcon
          icon='fa-light fa-circle-exclamation'
          className={iconStyle}
        />
      )
      break
  }

  const handleClose = () => {
    setIsAnimationActive(true)
    setTimeout(() => {
      setIsAlertVisible(false)
    }, 300)
  }

  const handleExpand = (e) => {
    e.preventDefault()
    setIsExpanded(!isExpanded)
  }

  const handleConfirm = () => {
    console.log("confirm")
  }

  // default alert banner
  const renderDefaultAlert = () => (
    <details
      open={false}
      className={`alert-banner--${type}  ${
        isAnimationActive ? "alert-banner--animation" : ""
      }`}
      role='alert'
      onClick={handleClose}
    >
      <summary
        className='alert-banner__summary'
        aria-labelledby='summary-title'
      >
        <div className='alert-banner__summary-icon'>{icon}</div>
        <div id='summary-title' className='alert-banner__summary-text'>
          {info}
        </div>
      </summary>
    </details>
  )

  // alert banner with close button
  const renderAlertWithCloseButton = () => (
    <details
      open={false}
      className={`alert-banner--${type} ${
        isAnimationActive ? "alert-banner--animation" : ""
      }`}
      role='alertdialog'
      aria-expanded='false'
    >
      <summary
        className='alert-banner__summary'
        aria-labelledby='summary-title'
      >
        <div className='alert-banner__summary-icon'>{icon}</div>
        <div id='summary-title' className='alert-banner__summary-text'>
          {info}
        </div>
        <button
          onClick={handleClose}
          className='alert-banner__button-close'
          aria-labelledby='close-button'
        >
          <FontAwesomeIcon
            id='close-button'
            icon='fa-light fa-square-xmark'
            className='alert-banner__button-close-icon'
          />
        </button>
      </summary>
    </details>
  )

  // expandable alert banner
  const renderExpandableAlert = () => (
    <details
      open={isExpanded}
      className={`alert-banner--${type} alert-banner--expandable ${
        isAnimationActive ? "alert-banner--animation" : ""
      }`}
      role='alertdialog'
      onClick={handleExpand}
    >
      <summary
        className='alert-banner__summary'
        aria-expanded={isExpanded ? true : false}
        aria-labelledby='summary-title'
      >
        <div className='alert-banner__summary-icon'>{icon}</div>
        <div id='summary-title' className='alert-banner__summary-text'>
          {info}
        </div>
        <button
          className='alert-banner__details-button'
          onClick={handleExpand}
          aria-labelledby='expand-button'
        >
          <FontAwesomeIcon
            id='expand-button'
            icon={
              isExpanded ? "fa-light fa-angle-up" : "fa-light fa-angle-down"
            }
            className='alert-banner__button-detail-icon'
          />
        </button>
      </summary>
      <p className='alert-banner__details-p'>{expandedContent}</p>
      <div
        className='alert-banner__button-group'
        aria-labelledby='button-group'
      >
        <Button
          id='alert-banner__button-1'
          className={"button__banner"}
          onClick={handleConfirm}
          buttonText={"Button"}
        />
        <Button
          id='alert-banner__button-2'
          className={"button__banner"}
          onClick={handleClose}
          buttonText={"Cancel"}
        />
      </div>
    </details>
  )

  return (
    <React.Fragment>
      {isAlertVisible
        ? isExpandable
          ? renderExpandableAlert()
          : isDialog
          ? renderAlertWithCloseButton()
          : renderDefaultAlert()
        : null}
    </React.Fragment>
  )
}
