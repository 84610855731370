import React, { useRef, useEffect, useState } from "react"
import "./fileUploaderModal.css"
import FileDetailsTable from "./FileDetailsTable"
import UserStorageSpace from "./UserStorageSpace"
import StatusMessage from "./StatusMessage"
import LinearProgress from "@material-ui/core/LinearProgress"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Button from "../button/Button"
import { fileOpen, directoryOpen } from "browser-fs-access"
import {
  isMobile,
  isTablet,
  isBrowser,
  BrowserView,
  MobileView,
} from "react-device-detect"

function FileUploaderModal({
  table_headers,
  modal_header,
  content_title,
  content_description,
  modal_ref,
  user_data,
  fn_remove_row,
  accepted_file_types,
  fn_upload_handler,
  disabled,
}) {
  const [files, setFiles] = useState([])
  const [errorFiles, setErrorFiles] = useState([])
  const [totalSize, setTotalSize] = useState(0)
  const [tableData, setTableData] = useState([])
  const [attachedTableData, setAttachedTableData] = useState([])

  const used_storage = user_data.current_usage_in_mb
  const total_storage = user_data.storage_quota_in_mb

  // result message
  const [resultMessage, setResultMessage] = useState({ msg: "", status: null })

  // file result list
  const [failedFileNames, setFailedFileNames] = useState([])
  const [passedFileNames, setPassedFileNames] = useState([])

  // flags
  const [receivedResponse, setReceivedResponse] = useState(false)
  const [loading, setLoading] = useState(false)

  // accepted file types
  const [fileTypesList, setFileTypesList] = useState([])

  useEffect(() => {
    if (files.length > 0 || errorFiles.length > 0) {
      let total_size = files.reduce((total, file) => total + file.file_size, 0)
      setTotalSize(total_size)
      // genereate table list
      generateTableDataList()
    } else {
      setReceivedResponse(false)
      setTotalSize(0)
    }

    if (accepted_file_types) {
      setFileTypesList(accepted_file_types)
    }
  }, [files, errorFiles, receivedResponse])

  function handleClose() {
    setFiles([])
    setTableData([])
    setAttachedTableData([])
    modal_ref.current.close()
  }

  // when file is dragged over the dropzone, prevent all default actions
  function handleDragOver(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  // when file is dropped, it will add to the existing filelist
  function handleDrop(e) {
    e.preventDefault()
    e.stopPropagation()

    setResultMessage({ msg: "", status: null })

    let passed_blobs = []
    let failed_blobs = []

    if (e.dataTransfer.files.length > 0) {
      let items = e.dataTransfer.items

      for (let item of items) {
        let item_entry = item.webkitGetAsEntry()

        if (item_entry.isFile) {
          let blob = item.getAsFile()

          if (!fileTypesList.includes(blob.type)) {
            failed_blobs.push({
              folder_name: "",
              file_name: blob.name,
              file_path: "/" + blob.name,
              file_size: blob.size,
              uploaded_date: "",
              message: "File type not supported!",
              status: "Error",
              file_blob: blob,
            })
          } else if (blob.size > 5000000) {
            failed_blobs.push({
              folder_name: "",
              file_name: blob.name,
              file_path: "/" + blob.name,
              file_size: blob.size,
              uploaded_date: "",
              message: "File size exceeds 5MB!",
              status: "Error",
              file_blob: blob,
            })
          } else {
            passed_blobs.push({
              folder_name: "",
              file_name: blob.name,
              file_path: "/" + blob.name,
              file_size: blob.size,
              uploaded_date: "",
              message: "File selected",
              status: "Selected",
              file_blob: blob,
            })
          }
        } else if (item_entry.isDirectory) {
          let blob = item.getAsFile()
          failed_blobs.push({
            folder_name: "",
            file_name: "/" + blob.name,
            file_size: blob.size,
            uploaded_date: "",
            message: "Folder drop not supported!",
            status: "Error",
            file_blob: blob,
          })
        }
      }
    }

    if (receivedResponse) {
      setFiles([...passed_blobs])
      setErrorFiles([...failed_blobs])
    } else {
      setFiles([...files, ...passed_blobs])
      setErrorFiles([...errorFiles, ...failed_blobs])
    }
    setReceivedResponse(false)
  }

  function generateTableDataList() {
    setTableData([])
    setAttachedTableData([])
    let generated_file_list = []
    let new_projects_list = []

    if (errorFiles.length > 0) {
      for (let file of errorFiles) {
        generated_file_list.push(file)
      }
    }

    if (receivedResponse) {
      if (failedFileNames.length > 0) {
        for (let file_name of failedFileNames) {
          let file = files.find((file) => file.name === file_name.file_path)
          if (file) {
            generated_file_list.push({
              folder_name: file.folder_name,
              file_name: file.file_name,
              file_path: file.file_path,
              file_size: file.file_size,
              uploaded_date: file.uploaded_date,
              message: file_name.error,
              status: "Rejected",
              file_blob: file.file_blob,
            })
          }
        }
      }
      if (passedFileNames.length > 0) {
        for (let file_name of passedFileNames) {
          let file = files.find((file) => file.name === file_name.file_path)
          if (file) {
            generated_file_list.push({
              folder_name: file.folder_name,
              file_name: file.file_name,
              file_path: file.file_path,
              file_size: file.file_size,
              uploaded_date: file.uploaded_date,
              message: "Imported successfully!",
              status: "Accepted",
              file_blob: file.file_blob,
            })
            new_projects_list.push(file_name.project_number)
          }
        }
      }
    } else {
      if (files.length > 0) {
        for (let file of files) {
          generated_file_list.push(file)
        }
      }
    }
    if (generated_file_list.length > 0) {
      let old_projects_list = JSON.parse(
        localStorage.getItem("new_projects_list")
      )
      if (old_projects_list) {
        new_projects_list = [...new_projects_list, ...old_projects_list]
      }
      setTableData([...generated_file_list])
      localStorage.setItem(
        "new_projects_list",
        JSON.stringify(new_projects_list)
      )
    }
  }

  function onSubmitHandler(e) {
    e.preventDefault()
    if (files.length > 0) {
      let attached_files = [...files]
      for (let file of attached_files) {
        file.status = "Attached"
        file.message = "File attached"
      }
      setAttachedTableData([...attached_files])
      fn_upload_handler(files)
      onResetHandler()
    }
  }

  function onResetHandler(e) {
    setFiles([])
    setTableData([])
    setAttachedTableData([])
    setPassedFileNames([])
    setFailedFileNames([])
    setReceivedResponse(false)
    setErrorFiles([])
  }

  async function handleOpenFile(e) {
    e.preventDefault()
    await fileOpen({
      // extensions: fileTypesList,
      multiple: true,
      mineTypes: fileTypesList,
    })
      .then((blobs) => {
        let failed_blobs = []
        let passed_blobs = []
        for (let i = 0; i < blobs.length; i++) {
          // limit file size to 5MB
          if (blobs[i].size > 5000000) {
            failed_blobs.push({
              folder_name: "",
              file_name: blobs[i].name,
              file_path: "/" + blobs[i].name,
              file_size: blobs[i].size,
              uploaded_date: "",
              message: "File size exceeds 5MB!",
              status: "Error",
              file_blob: blobs[i],
            })
            // blobs.splice(i, 1)
            // i--
          } else {
            passed_blobs.push({
              folder_name: "",
              file_name: blobs[i].name,
              file_path: "/" + blobs[i].name,
              file_size: blobs[i].size,
              uploaded_date: "",
              message: "File selected",
              status: "Selected",
              file_blob: blobs[i],
            })
          }
        }

        if (receivedResponse) {
          setFiles([...passed_blobs])
          setErrorFiles([...failed_blobs])
        } else {
          setFiles([...files, ...passed_blobs])
          setErrorFiles([...errorFiles, ...failed_blobs])
        }

        setReceivedResponse(false)
      })
      .catch((err) => {})
  }

  async function handleOpenDirectory(e) {
    e.preventDefault()
    await directoryOpen({
      // recursive: true, // restrict open the subfolders
    })
      .then((response) => {
        const blobs = response.filter((blob) => blob.name !== ".DS_Store")
        const folder_name = blobs[0].directoryHandle.name

        let failed_blobs = []
        let passed_blobs = []
        for (let i = 0; i < blobs.length; i++) {
          // limit file size to 5MB
          if (blobs[i].size > 5000000) {
            failed_blobs.push({
              folder_name: folder_name,
              file_name: blobs[i].name,
              file_path: folder_name + "/" + blobs[i].name,
              file_size: blobs[i].size,
              uploaded_date: "",
              message: "File size exceeds 5MB!",
              status: "Error",
              file_blob: blobs[i],
            })
          } else if (!fileTypesList.includes(blobs[i].type)) {
            failed_blobs.push({
              folder_name: folder_name,
              file_name: blobs[i].name,
              file_path: folder_name + "/" + blobs[i].name,
              file_size: blobs[i].size,
              uploaded_date: "",
              message: "File type not supported!",
              status: "Error",
              file_blob: blobs[i],
            })
          }
          //add folder name to the file object
          else {
            passed_blobs.push({
              folder_name: folder_name,
              file_name: blobs[i].name,
              file_path: folder_name + "/" + blobs[i].name,
              file_size: blobs[i].size,
              uploaded_date: "",
              message: "File selected",
              status: "Selected",
              file_blob: blobs[i],
            })
          }
        }

        if (receivedResponse) {
          setFiles([...passed_blobs])
          setErrorFiles([...failed_blobs])
        } else {
          setFiles([...files, ...passed_blobs])
          setErrorFiles([...errorFiles, ...failed_blobs])
        }

        setReceivedResponse(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function removeRow(file_name) {
    let new_error_files = [...errorFiles]
    let selectedErrorFileIndex = new_error_files.findIndex(
      (file) => file.file_name === file_name
    )
    if (selectedErrorFileIndex !== -1) {
      new_error_files.splice(selectedErrorFileIndex, 1)
    }
    setErrorFiles([...new_error_files])

    let new_table_data = [...tableData]
    let selectedRowIndex = new_table_data.findIndex(
      (file) => file.file_name === file_name
    )
    if (selectedRowIndex !== -1) {
      new_table_data.splice(selectedRowIndex, 1)
    }
    setTableData([...new_table_data])

    let new_files = [...files]
    let selectedFileIndex = new_files.findIndex(
      (file) => file.file_name === file_name
    )
    if (selectedFileIndex !== -1) {
      new_files.splice(selectedFileIndex, 1)
    }
    setFiles([...new_files])
  }

  return (
    <dialog
      ref={modal_ref}
      className='file-uploader'
      onClose={handleClose ? handleClose : null}
    >
      <div className='dialog-header__container'>
        <p className='dialog__header'>{modal_header}</p>
        <FontAwesomeIcon
          icon='fa-light fa-square-xmark'
          onClick={handleClose}
          className='dialog__close-icon'
        />
      </div>

      <div className='dialog__text'>
        <p>{content_title}</p>
        <p>{content_description}</p>
      </div>
      <div className='storage-indicator__container'>
        <UserStorageSpace
          used_storage={used_storage}
          total_storage={total_storage}
        />
        <div className='file__stats'>
          <p>Selected: {files.length}</p>
          <p>
            Total Size:
            {totalSize > 1000000
              ? (totalSize / 1000000).toFixed(1) + " MB"
              : (totalSize / 1000).toFixed(1) + " KB"}
          </p>
        </div>
      </div>
      <div
        className='dropzone__container'
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {attachedTableData.length > 0 ? (
          <FileDetailsTable
            table_headers={table_headers}
            data_list={attachedTableData}
            set_attached_data_list={setAttachedTableData}
            fn_remove_row={fn_remove_row}
            isEditable={false}
          />
        ) : tableData.length > 0 ? (
          <FileDetailsTable
            table_headers={table_headers}
            data_list={tableData}
            set_data_list={setTableData}
            fn_remove_row={removeRow}
            isEditable={true}
          />
        ) : (
          <div className='file-input-placeholder'>Drag and drop files here</div>
        )}
      </div>

      <div className='file-uplpoad__buttons'>
        <div className='add-buttons__container'>
          <Button
            buttonText='Select Files'
            onClick={handleOpenFile}
            className='button button__large button__large--primary'
            type='button'
          />
          <Button
            buttonText='Select Folder'
            onClick={handleOpenDirectory}
            className='button button__large button__large--secondary'
          />
        </div>
        <div className='action-buttons__container'>
          <Button
            buttonText='Attach'
            onClick={onSubmitHandler}
            className='button button__large button__large--primary'
            type='submit'
          />
          <Button
            buttonText='Remove All'
            onClick={onResetHandler}
            className='button button__large button__large--secondary'
            type='reset'
          />
        </div>
      </div>
      <div className='progress-bar-container'>
        {loading && (
          <div>
            <p className='progress-warning-message'>
              Importing may take a few minutes
            </p>
            <LinearProgress variant='indeterminate' />
          </div>
        )}
      </div>
      <div className='bottom-container'>
        <div className='status-message-container'>
          {receivedResponse && resultMessage.msg_top.length > 0 && (
            <StatusMessage
              completed={resultMessage.status}
              msg_top={resultMessage.msg_top}
              msg_bot={resultMessage.msg_bot}
            />
          )}
        </div>
      </div>
    </dialog>
  )
}

export default FileUploaderModal
